/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';

@tailwind base;
@tailwind components;
@tailwind utilities;


.margins {
  @apply container m-auto px-3 md:px-0;
}


.general-text {
  @apply text-3xl font-bold mb-2;
}

.paginator-button {
  @apply bg-primary-500 text-black hover:bg-secondary-950 hover:text-primary-500 p-2;
}


button.slick-arrow:before {
  /*all: unset;*/
  color: black;
}
